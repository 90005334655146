.otp-input {
    background-color: #ececec !important;
    border: none !important;
    text-align: center;
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #444 !important;
}

.ng-otp-input-wrapper .otp-input:not(:last-child) {
    margin-right: 6px !important;
}