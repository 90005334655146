//form

label {
  font-size: 14px;
  color: #5e5873;
  margin-bottom: 4px !important;

  & span {
    &.required {
      color: var(--bs-form-invalid-border-color);
    }
  }
}

.password-metter {
  & label {
    margin-bottom: 0px !important;
  }
}


.form-control {
  font-size: 14px;
  padding: 10px;
  font-weight: 400;
  line-height: 1.45;

  &.ng-invalid {
    &.ng-touched {
      border-color: var(--bs-form-invalid-border-color);
      padding-right: calc(1.5em + .75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(.375em + .1875rem) center;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
  }
}

// .form-check-input {
//   &.ng-invalid {
//     &.ng-touched {
//       border-color: var(--bs-form-invalid-border-color);
//     }
//   }
// }

.ng-invalid {
  &.ng-touched {
    &~.invalid-feedback {
      display: block;
    }
  }
}

app-password-meeter .invalid-feedback {
  display: block;
}

.input-group {
  &.has-validation .form-control {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.invalid-feedback {
  font-size: 12px;
}

.form-control:focus,
.form-control:focus {
  box-shadow: none;
}

.form-control::placeholder {
  opacity: 0.9;
}

.input-group-text {
  &.country-number {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
}

//end form

.pointer {
  cursor: pointer !important;
}

.card,
.card .card-footer {
  border-color: #ebe9f1;
}