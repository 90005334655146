@import 'ngx-toastr/toastr';
@import "styles/toastr.override.scss";
@import "styles/tabs.override.scss";
@import "styles/bootstrap.override.scss";
@import "styles/otp.override.scss";

body {
  background-color: #f8f8f8;
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  margin: 0;
  height: 100%;
  direction: ltr;
  color: #40444d;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  text-rendering: optimizeLegibility;
}

.h-100 {
  height: 100% !important;
}

// padding
.pd-20 {
  padding: 20px !important;
}

.h-title {
  text-align: center;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 22px !important;
  line-height: 2rem !important;
}

.h-subtitle {
  text-align: center;
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.pointer {
  cursor: pointer;
}

.about {
  color: #0d6efd;
}