.tab-title {
    font-size: 16px;
    line-height: 21px;
    color: rgb(18, 52, 77);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:focus-visible,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link {
    color: rgb(18, 52, 77);
    color: #495057;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    border-bottom: 2px solid #0d6efd;
    font-weight: 500;
}